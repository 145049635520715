import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Australia from '../assets/svg/australia.js'
import NoArtificial from '../assets/svg/no-artificial.js'
import Family from '../assets/svg/family.js'
import GlutenFree from '../assets/svg/gluten-free.js'

let SalesPoint = ({title, Image, className}) => (
  <Col className={className}>
    <div className="text-center box-hover">
      <Image height={100}/>
      <h4 className="mt-3">{title}</h4>
    </div>
  </Col>
)

export default () => (
  <div className="py-6 bg-light text-dark">
    <Container>
      <Row>
        <SalesPoint title="100% Australian Owned" Image={Australia} className="color-green"/>
        <SalesPoint title="No Artificial Flavours" Image={NoArtificial} className="color-yellow"/>
        <SalesPoint title="Family Owned" Image={Family} className="color-purple"/>
        <SalesPoint title="Gluten Free" Image={GlutenFree} className="color-blue"/>
      </Row>
    </Container>
  </div>
)
