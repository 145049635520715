import React from "react";
import Slider from "react-slick";
import banner1 from '../assets/images/banner-1.jpg'
import banner2 from '../assets/images/banner-2.png'
import website from '../data/website.json'

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        <img src={banner1} alt={website.name}/>
        <img src={banner2} alt={website.name}/>
      </Slider>
    );
  }
}

export default SimpleSlider
