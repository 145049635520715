import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import history from '../assets/images/history-banner.png'
import Link from './link';

export default () => (
  <div className="made-in-melany d-flex py-5">
    <Container className="py-5">
      <img src={history} style={{width: '100%'}} alt="Made in Melany"/>
      <Row className="d-flex justify-content-center mt-5 pt-5">
        <Col lg={8}>
          <div className="text-center text-dark">
            <h2 className="h1">
              Made in Maleny since 2007
            </h2>
            <p>Here at Raybek Foods, we aim to meet the ever changing market by continuously striving to provide the freshest products possible by only using the finest quality ingredients.</p>
            <Link className="btn btn-secondary btn-lg mt-3" to="/about">About Us</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)
