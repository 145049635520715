import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider'
import MadeInMelany from '../components/made-in-melany'
import SupportingFarmers from '../components/supporting-farmers'
import Benefits from '../components/benefits'
import ProductSlider from '../components/product-slider'
import yoghurts from '../data/yoghurts'
import dips from '../data/dips'
import antipastos from '../data/anti-pasto'

export default ({data}) => (
  <Layout>
    <SEO title="Home" />
    <div className="slider">
      <Slider/>
    </div>
    <ProductSlider productName="Yoghurts" url="/yoghurts" products={data.yoghurts} subtitle="Raybek Foods Greek Yoghurt has been proven to be a taste like no other!"/>
    <ProductSlider productName="Dips" url="/dips" products={dips} subtitle="Raybek Foods Gourmet Dip range is the ultimate party starter!"/>
    <ProductSlider productName="Anti-Pasto Range" products={antipastos} url="/antipasto" subtitle="Raybek Foods Anti- Pasto ranges come in both bulk 2kg and 250g retail pack sizing to cater for all aspects of the market."/>
    <MadeInMelany/>
    <Benefits/>
    <SupportingFarmers/>
  </Layout>
)

export const query = graphql`
  query {
    yoghurts: allPrismicProducts(filter: {data: {product_type: {eq: "yoghurt"}}}) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`
