import React from 'react';
import Slider from "react-slick";
import Link from "./link"
import Img from 'gatsby-image/withIEPolyfill'

let Product = ({product}) => (
  <div className="py-5 px-4 d-flex justify-content-center flex-column align-items-center">
    {product.image && !product.image.localFile ? <img src={product.image} style={{width: '100%'}} alt={product.name} className="mb-3 mb-md-4 json-img"/> : ''}
    {product.image && product.image.localFile ?
      <Img
        fluid={product.image.localFile.childImageSharp.fluid}
        alt={product.name}
        className="mb-3 mb-md-4"
        style={{
          width: '50%'
        }}
        objectFit="contain"
      /> : ''}
    <h4 style={{height: '5rem'}}>{product.name}</h4>
  </div>
)

class ProductSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      render: false
    }
  }

  componentDidMount() {
    this.setState({
      render: true
    })
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 5,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
      ]
    };
    let {products} = this.props
    if (!products.edges) {
      products = products.filter(p => p.image)
    } else {
      products = products.edges.map(p => p.node.data).filter(p => p.image.localFile != null)
    }
    return (
      <>
        {this.state.render ? <Slider {...settings}>
          {products.map((product, i) => (
            <div key={i}>
              <Product product={product}/>
            </div>
          ))}
        </Slider> : ''}
      </>
    );
  }
}

export default ({productName, products, url, subtitle}) => (
  <div className={"py-5 products-slider " + productName.toLowerCase()}>
    <div className='text-center'>
      <h2 className="mb-3">{productName}</h2>
      <h5>{subtitle}</h5>
      {products ? (
        <ProductSlider products={products}/>
      ) : ''}
      <Link className="btn btn-primary btn-lg" to={url}>
        View {productName}
      </Link>
    </div>
  </div>
)
