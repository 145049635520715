import React from 'react'

const SvgComponent = props => (
  <svg viewBox="0 0 512 510" {...props}>
    <title>{'Asset 3'}</title>
    <g data-name="Layer 2">
      <g data-name="Capa 1">
        <path d="M445.62 247.36a75 75 0 10-107.46-.23 106 106 0 00-39 26.31 59.83 59.83 0 00-86.42 0 106 106 0 00-38.95-26.31 75 75 0 10-107.46.23A105.16 105.16 0 000 345v150a15 15 0 0015 15h482a15 15 0 0015-15V345a105.16 105.16 0 00-66.38-97.64zM392 150a45 45 0 11-45 45 45 45 0 0145-45zM256 285a30 30 0 11-30 30 30 30 0 0130-30zM120 150a45 45 0 11-45 45 45 45 0 0145-45zM30 345a75.08 75.08 0 0175-75h31a75.43 75.43 0 0161.44 32A59.68 59.68 0 00209 352.19 75.11 75.11 0 00166 420v60H30zm166 135v-60a45 45 0 0145-45h30a45 45 0 0145 45v60zm286 0H346v-60a75.1 75.1 0 00-43-67.81A59.66 59.66 0 00314.56 302 75.41 75.41 0 01376 270h31a75.09 75.09 0 0175 75z" />
        <path d="M213.32 121a250.1 250.1 0 0034.82 26.78 15 15 0 0015.72 0A250.76 250.76 0 00298.68 121C323.44 98.1 336 75.85 336 54.86c0-47.67-47.41-70-80-43.57-32.66-26.54-80-4-80 43.57 0 20.99 12.56 43.24 37.32 66.14zm30.05-79.24a15 15 0 0025.26 0C282.37 20.32 306 30.32 306 54.86c0 20.46-28 46.83-50 62.15-18.43-13-50-39.9-50-62.15 0-24.47 23.59-34.6 37.37-13.11z" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
