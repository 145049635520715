import React from 'react';
import australianMade from '../assets/images/australian-made.svg';

export default () => (
  <div className="supporting-farmers d-flex align-items-center flex-column justify-content-center">
    <div className="text-center">
      <img src={australianMade} height={150} className="mb-5"/>
      <div className="text-center">
        <h2 className="h1 mb-5">Supporting Australian Farmers</h2>
        <div className="text-box">Locally Sourced Produce</div>
      </div>
    </div>
  </div>
)
