import basilPesto from '../assets/images/dips/Basil-pesto.png'
import fireRedPesto from '../assets/images/dips/Fire-red-pesto.png'
import beetrootFeta from '../assets/images/dips/Beetroot-&-Feta.png'
import spinachFeta from '../assets/images/dips/Spinach-&-Feta-Pesto.png'
import qldBluePumpkin from '../assets/images/dips/Qld-blue-pumpkin-dip.png'
import springOnion from '../assets/images/dips/Spring-Onion-Dip.png'
import roastedCapcicumAlmond from '../assets/images/dips/Roasted-Capcicum-&-Almond.png'
import spicyCoriander from '../assets/images/dips/spicy-coriander.png'
import taramasalata from '../assets/images/dips/Taramosalata.png'
import brucheta from '../assets/images/dips/Brucheta.png'
import basilFetaSemiDried from '../assets/images/dips/Basil-&-Feta-Semi-Dried-Tomato.png'
import babaGanoush from '../assets/images/dips/Baba-Ganoush.png'
import traditionalHommus from '../assets/images/dips/Traditional-Hommus.png'
import tzatziki from '../assets/images/dips/Tzatziki.png'
import oliveTapenade from '../assets/images/dips/olive-tapenade.png'
import frenchOnion from '../assets/images/dips/French-Onion.png'

let dips = [
  {name: "Premium Basil Pesto", image: basilPesto, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Basil Puree (28%), Spinach Puree, Parmesan Cheese, Salt, Citric Acid, Preservative (E202 & 211), Canola Oil, Cashew Nuts, Sugar & Garlic"
  },
  {name: "Fire Roasted Red Pesto", image: fireRedPesto, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Pumpkin (41%), Neufchatel, Sugar, Salt, Citric Acid, Preservative (E202 & 211), Canola Oil, Pepper, Cumin, Mixed Herbs, Chilli & Coriander."
  },
  {name: "Beetroot & Feta Pesto", image: beetrootFeta, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Beetroot Puree (60%), Neufchatel, Danish Fetta, Garlic, Salt, Citric Acid, Preservative (E202 & 211), Canola Oil, Mint & Sugar."
  },
  {name: "Spinach & Feta Pesto", image: spinachFeta, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Spinach Puree (40%), Danish Fetta, Neufchatel, Salt, Sugar, Garlic, Citric Acid, Preservative (E202 & 211), Canola Oil and Cashew Nuts."
  },
  {name: "Qld Blue Pumpkin Pesto", image: qldBluePumpkin, sizes: ["1kg", "2kg", "5kg", "10kg"]},
  {name: "Spring Onion Dip", image: springOnion, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Neufchatel (69%), Spring Onion, Pepper, Sugar, Garlic, Citric Acid, Preservative (E202 & 211) and Canola Oil."
  },
  {name: "Roasted Capsicum & Almond Dip", image: roastedCapcicumAlmond, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Capsicum (55%), Almonds, Parmesan, Sun Dried Tomato, Coriander, Salt, Garlic, Sugar, Citric Acid, Preservative (E202 & 211), Pepper, Mixed Herbs & Canola Oil."
  },
  {name: "Spicy Coriander Dip", image: spicyCoriander, sizes: ["1kg", "2kg", "5kg", "10kg"]},
  {name: "Bruschetta", image: brucheta, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Eggplant (24%), Zucchini (24%), Pumpkin, Sun Dried Tomato, Bell Pepper, Balsamic Onion, Mixed Herbs, Cumin, Coriander Powder, Pepper, Sugar, Salt, Citric Acid, Garlic, Preservative (E202 & 211), Canola Oil and Cashew Nuts."
  },
  {name: "Basil, Feta & Semi Dried Tomato", image: basilFetaSemiDried, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Sun Dried Tomato (43%), Neufchatel, Danish Fetta, Basil, Salt, Citric Acid, Preservative (E202 & 211), Sugar, Canola Oil, Garlic & Mixed Herbs."
  },
  {name: "Baba Ganoush", image: babaGanoush, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Yoghurt (53%), Tahini, Canola Oil, Sugar, Salt, Citric Acid, Preservative (E202 & 211), Eggplant Pulp, Parsley & Garlic."
  },
  {name: "Taramosalata", image: taramasalata, sizes: ["1kg", "2kg", "5kg", "10kg"]},
  {name: "Traditional Hommus", image: traditionalHommus, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Chickpeas (58%), Tahini, Canola Oil, Cumin, Citric Acid, Preservative (E202 & 211), Salt & Sugar."
  },
  {name: "Tzatziki", image: tzatziki, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Yoghurt (78%), Lebanese Cucumber, Sugar, Salt, Citric Acid, Preservative (E202 & 211), Garlic, Mint & Canola Oil."
  },
  {name: "Kalamata Olive Tapenade", image: oliveTapenade, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Kalamata Olives (72%), Garlic, Mixed Herbs, Salt, Sugar, Citric Acid and Canola Oil"
  },
  {name: "French Onion Dip", image: frenchOnion, sizes: ["1kg", "2kg", "5kg", "10kg"],
    ingredients: "Neufchatel (51%), French Onion Soup Mix, Salt, Sugar, Caramelised Onion Strips, Canola Oil, Citric Acid and Preservative (E202 & 211)."
  }
]

export default dips
