import apple from "../assets/images/yoghurts/apple-cinnamon.png"
import blueberry from "../assets/images/yoghurts/blueberry.png"
import mango from "../assets/images/yoghurts/mango.png"
import mixed from "../assets/images/yoghurts/mixed-berry.png"
import natural from "../assets/images/yoghurts/natural.png"
import passionfruit from "../assets/images/yoghurts/passionfruit.png"
import rhubarb from "../assets/images/yoghurts/rhubarb-&-strawberry.png"

let yoghurts = [
  {name: 'Apple Cinnamon', image: apple, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Blueberry', image: blueberry, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Mango', image: mango, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Mixed Berry', image: mixed, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Natural Greek', image: natural, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Passionfruit', image: passionfruit, subtitle: 'Gluten Free | 96% fat free', sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]},
  {name: 'Rhubarb & Strawberry', image: rhubarb, subtitle: 'Gluten Free | 96% fat free, sizes: ["300g", "350g", "500g", "700g", "1kg", "10kg"]'}
]

export default yoghurts
